import { GatsbySeo } from 'gatsby-plugin-next-seo'
import MainFormRegisterbicycle from 'src/components/servicos/ServicesPage/GarantiaVitalicia/RegistreBicicleta'

function Page() {
  return (
    <>
      <GatsbySeo title="Registre sua Bicicleta | Decathlon" noindex nofollow />
      <MainFormRegisterbicycle />
    </>
  )
}

export default Page
